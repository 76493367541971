























































































import { Component, Vue } from "vue-property-decorator";
import { MenuButton } from "@/types/components/navbar/";

/**
 * Navbar component
 *
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component
export default class Navbar extends Vue {
    private menuButtons: MenuButton[] = [
        {
            to: { name: "home" },
            exact: true,
            text: this.$t("buttons.home").toString(),
        },
        {
            to: { name: "about" },
            exact: true,
            text: this.$t("buttons.about").toString(),
        },
        {
            to: { name: "projects" },
            exact: true,
            text: this.$t("buttons.projects").toString(),
        },
        {
            to: { name: "activities" },
            exact: true,
            text: this.$t("buttons.activities").toString(),
        },
        {
            to: { name: "sponsoring" },
            exact: true,
            text: this.$t("buttons.sponsoring").toString(),
        },
        {
            to: { name: "links" },
            exact: true,
            text: this.$t("buttons.links").toString(),
        },
    ];
}
