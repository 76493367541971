
























































































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import ContentCard from "@/components/content-card/ContentCard.vue";
import { Post } from "@/types/components/posts";
import axios, { APIResponse } from "@/plugins/axios";
import AlertModule, { AlertType } from "@/store/modules/alertmodule";
import PostList from "@/components/posts/PostList.vue";
import Marquee from "@/components/Marquee.vue";

@Component({
    components: {
        ContentCard,
        PostList,
        Marquee,
    },
})
export default class AboutUs extends Vue {
    /*private posts: Post[] = [];

    private async created(): Promise<void> {
        await this.fetchData();
    }

    private async fetchData() {
        try {
            this.posts = await this.fetchProjects();
        } catch (err) {
            const errorMessage =
                err instanceof Error ? err.message : (err as string);
            console.log(errorMessage);
        }
    }

    private async fetchProjects(): Promise<Post[]> {
        const response = (
            await axios.get<APIResponse<Post[]>>("/posts/category/1")
        ).data;

        if (response.data.length <= 0) {
            console.log("Projects are empty");
        }
        if (response.status === "error") {
            throw new Error(response.message || "unknownError");
        }

        return response.data;
    } */
}
